<template>
  <div>
    <ModalCreateBlockRule
      v-if="showCreateBlockrule"
      :showModal="showCreateBlockrule"
      :typeId="1"
      :itemId="space.Id"
      :meetingtypeId="meetingtypeId"
      :onClickCancel="hideModal"
      @newRuleCreated="newRuleCreated"
    />

    <ModalDeleteBlockRule
      v-if="showDeleteBlockrule"
      :showModal="showDeleteBlockrule"
      :blockrule="selectedBlockrule"
      :onClickCancel="hideModal"
      @blockruleDeleted="blockruleDeleted"
    />

    <ui-section-header :showAdd="true" :addFunction="setShowCreateBlockrule">
      <template v-slot:title>{{
        $t('Views.Spaces.SpaceDetail.Subtitle_Block-rules')
      }}</template>
    </ui-section-header>

    <Message :message="$t('Views.Spaces.SpaceDetail.Message_BlockRulesInfo')" />

    <table
      v-if="blockrules.length > 0"
      class="table is-fullwidth is-striped is-hoverable"
    >
      <thead>
        <tr>
          <th>
            {{ $t('Components.Spaces.SpaceBlockRules.TableHeader_Name') }}
          </th>
          <th>
            {{ $t('Components.Spaces.SpaceBlockRules.TableHeader_Period') }}
          </th>
          <th>
            {{ $t('Components.Spaces.SpaceBlockRules.TableHeader_TimeSlot') }}
          </th>
          <th>
            {{ $t('Components.Spaces.SpaceBlockRules.TableHeader_Days') }}
          </th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="blockrule in blockrules" :key="blockrule.Id">
          <td>{{ blockrule.Name }}</td>
          <td>
            {{
              blockrule.StartDate | dateFormat($i18n.locale, 'shortDateFormat')
            }}
            -
            {{
              blockrule.EndDate | dateFormat($i18n.locale, 'shortDateFormat')
            }}
          </td>
          <td>
            {{ blockrule.StartMinutes | minutesToTime }} -
            {{ blockrule.EndMinutes | minutesToTime }}
          </td>
          <td>
            <span v-for="(Day, index) in blockrule.Days" :key="index"
              >{{ Day | getDayName($i18n.locale, true) }},
            </span>
          </td>
          <td class="has-text-right">
            <a @click="setShowDeleteBlockrule(blockrule)">
              <span class="icon has-text-danger">
                <font-awesome-icon :icon="['fas', 'trash-alt']" />
              </span>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

const Message = () => import('@/components/UI/Message')
const ModalCreateBlockRule = () =>
  import('@/components/BlockRules/ModalCreateBlockRule')
const ModalDeleteBlockRule = () =>
  import('@/components/BlockRules/ModalDeleteBlockRule')

export default {
  components: {
    Message,
    ModalCreateBlockRule,
    ModalDeleteBlockRule,
  },

  props: {
    meetingtypeId: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      selectedBlockrule: null,
      showCreateBlockrule: false,
      showDeleteBlockrule: false,
    }
  },

  computed: {
    ...mapState('spaceStore', ['space']),
    //...mapGetters('locationStore', ['spaceRights']),

    blockrules() {
      if (this.space) {
        return this.space.BlockRules.filter(
          (br) =>
            br.MeetingtypeId === 0 || br.MeetingtypeId === this.meetingtypeId
        )
      }
      return []
    },
  },

  created() {},

  methods: {
    ...mapMutations('spaceStore', ['setSpace']),

    newRuleCreated(newRule) {
      this.space.BlockRules.push(newRule)
      this.setSpace(this.space)
    },

    blockruleDeleted(rule) {
      let index = this.space.BlockRules.findIndex((br) => br.Id === rule.Id)

      if (index > -1) {
        this.space.BlockRules.splice(index, 1)
        this.setSpace(this.space)
      }
    },

    setShowCreateBlockrule() {
      this.showCreateBlockrule = true
    },

    setShowDeleteBlockrule(rule) {
      this.showDeleteBlockrule = true
      this.selectedBlockrule = rule
    },

    hideModal() {
      this.showCreateBlockrule = false
      this.showDeleteBlockrule = false

      this.selectedBlockrule = null
    },
  },
}
</script>

<style></style>
